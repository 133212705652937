import { Injectable } from '@angular/core';

// import { SwUpdate } from '@angular/service-worker';

@Injectable({ providedIn: 'root' })
export class HandleUnrecoverableStateService {
  // @TODO only available in Angular 11.X
  // constructor(updates: SwUpdate) {
  //   updates.unrecoverable.subscribe(event => {
  //     if (confirm(`An error occurred that we cannot recover from:\n${event.reason}\n\n` + 'Please reload the page.')) {
  //       caches.keys().then((names) => {
  //         for (const name of names) {
  //           caches.delete(name);
  //         }
  //       });
  //     }
  //   });
  // }

  public cleanUp(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
            console.log('Cleaned a service worker!');
          }
        })
        .catch((err) => {
          console.error('Service Worker registration failed: ', err);
        });
    }

    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
      console.log('SW cache cleaned!');
    });
  }
}
