import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AsideMenuItem } from '@core/enums/aside-menu-item.model';
import { AppRoutes } from '@core/enums/routes.enum';
import { Observable, zip } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { CacheService } from '@core/services/cache.service';
import { AppInjector } from '../../app-injector.service';
import { ResponsiveService } from '@shared/services/responsive.service';
import { DestroyBase } from '@core/base/destroy.class';
import { ScreenType } from '@core/enums/screen-type.enum';
import { MarketDemandService } from '@core/api/market-demand.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService extends DestroyBase {
  public menus: AsideMenuItem[] = [];
  public customMenus: AsideMenuItem[] = [];
  public menuItems: { [key: string]: AsideMenuItem };
  public screenType: ScreenType;

  constructor(
    private translateService: TranslateService,
    private responsiveService: ResponsiveService,
  ) {
    super();

    this.generateMenus();
    this.populateMenus();
  }

  public getPageRouteByKey(key: string): string {
    const navigationItem = this.customMenus.find((item) => {
      return item.key_index === key;
    });

    return navigationItem ? navigationItem.link : '';
  }

  public createProfileMenu(): Observable<MenuItem[]> {
    const injector = AppInjector.getInjector();
    const cacheService = injector.get(CacheService); // @TODO: This prevents cyclical for now

    return zip(
      this.translateService.stream('dxp.profile-menu.profile.label'),
      this.translateService.stream('dxp.profile-menu.reload.label'),
      this.translateService.stream('dxp.profile-menu.logout.label'),
    ).pipe(
      map(([profileLabel, reloadLabel, logoutLabel]) => {
        return [
          {
            label: profileLabel,
            icon: 'fas fa-user-circle',
            routerLink: `/${AppRoutes.admin}/${AppRoutes.user}/${AppRoutes.profile}`,
          },
          {
            label: reloadLabel,
            icon: 'fas fa-sync',
            command: () => {
              cacheService.clearCache(true);
            },
          },
          {
            label: logoutLabel,
            icon: 'fas fa-sign-out-alt',
            command: () => {
              cacheService.forceLogout();
            },
          },
        ] as MenuItem[];
      }),
    );
  }

  public populateMenus(): AsideMenuItem[] {
    this.getScreenSize();

    this.menus = [
      this.menuItems.dashboard,
      this.menuItems.production,
      this.menuItems.harvest,
      this.menuItems.grading,
      this.menuItems.gradedStock,
      this.menuItems.shipment,
      this.menuItems.logistic,
      this.menuItems.sales,
      this.menuItems.auctionSales,
      this.menuItems.allocation,
      this.menuItems.packageManagement,
      this.menuItems.fulfillment,
      this.menuItems.finance,
      this.menuItems.manage,
    ];

    if (this.screenType !== ScreenType.MD) {
      const index = this.menus.length - 1; // before manage

      this.menus.splice(index, 0, this.menuItems.insight);
    }

    this.customMenus = [...this.menus, MarketDemandService.marketDemandMenu];

    return this.menus;
  }

  public getScreenSize(): void {
    this.responsiveService.screenTypeChanged$.pipe(takeUntil(this.destroy$)).subscribe((newScreenType) => {
      this.screenType = newScreenType;
    });
  }

  private generateMenus(): void {
    this.menuItems = {
      dashboard: {
        icon: 'fas fa-chart-bar',
        label: this.translateService.stream('dxp.sidebar.dashboard'),
        link: `${AppRoutes.admin}/${AppRoutes.dashboard}`,
        permission: ['dxp.dashboard.show'],
        key_index: 'dashboard_index',
      },
      sales: {
        icon: 'fas fa-sack-dollar',
        link: `${AppRoutes.admin}/${AppRoutes.order}`,
        label: this.translateService.stream('dxp.sidebar.order'),
        permission: ['dxp.order.show'],
        key_index: 'sales_index',
      },
      production: {
        icon: 'fas fa-industry-alt',
        label: this.translateService.stream('dxp.sidebar.production'),
        link: `${AppRoutes.admin}/${AppRoutes.production}`,
        permission: ['dxp.production.show'],
        key_index: 'production_index',
      },
      insight: {
        icon: 'fas fa-glasses',
        label: this.translateService.stream('dxp.sidebar.insight'),
        link: `${AppRoutes.admin}/${AppRoutes.insight}`,
        permission: ['dxp.harvest-planning.insight'],
        key_index: 'insights_index',
      },
      harvest: {
        icon: 'fas fa-tractor',
        label: this.translateService.stream('dxp.sidebar.harvest'),
        link: `${AppRoutes.admin}/${AppRoutes.harvestDeliveryNote}/${AppRoutes.overview}`,
        permission: ['dxp.harvest-dashboard.show'],
        key_index: 'harvest_index',
      },
      grading: {
        icon: 'fas fa-random',
        label: this.translateService.stream('dxp.sidebar.grading'),
        link: `${AppRoutes.admin}/${AppRoutes.grading}/${AppRoutes.overview}`,
        permission: ['dxp.grading-dashboard.show'],
        key_index: 'grading_index',
      },
      gradedStock: {
        icon: 'fas fa-warehouse-alt',
        label: this.translateService.stream('dxp.sidebar.graded-stock'),
        link: `${AppRoutes.admin}/${AppRoutes.gradedStock}/${AppRoutes.overview}`,
        permission: ['dxp.boxing-dashboard.show'],
        key_index: 'stock_index',
      },
      allocation: {
        icon: 'fas fa-cart-arrow-down',
        label: this.translateService.stream('dxp.sidebar.allocation'),
        link: `${AppRoutes.admin}/${AppRoutes.allocation}`,
        key_index: 'allocation_index',
        permission: ['dxp.allocation.show', 'dxp.allocation.manage'],
      },
      packageManagement: {
        icon: 'fas fa-box-open',
        label: this.translateService.stream('dxp.sidebar.package-management'),
        link: `${AppRoutes.admin}/${AppRoutes.packageManagement}`,
        key_index: 'package_management_index',
        permission: ['dxp.fust.dashboard.show'],
      },
      fulfillment: {
        icon: 'fas fa-pallet',
        label: this.translateService.stream('dxp.sidebar.fulfillment'),
        link: `${AppRoutes.admin}/${AppRoutes.fulfillment}`,
        key_index: 'fulfillment_index',
        permission: ['dxp.fulfillment-dashboard.show'],
      },
      logistic: {
        icon: 'fas fa-plane-departure',
        label: this.translateService.stream('dxp.sidebar.logistics'),
        link: `${AppRoutes.admin}/${AppRoutes.logistics}`,
        key_index: 'logistics_index',
        permission: ['dxp.logistics.show'],
      },
      shipment: {
        icon: 'fas fa-box-open',
        label: this.translateService.stream('dxp.sidebar.shipment'),
        link: `${AppRoutes.admin}/${AppRoutes.shipment}`,
        permission: ['dxp.packing-order.show', 'dxp.packing-order.manage'],
        key_index: 'packing_shipping_index',
      },
      shipping: {
        icon: 'fas fa-plane',
        label: this.translateService.stream('dxp.sidebar.shipping'),
        key_index: 'shipping_index',
      },
      auctionSales: {
        icon: 'fas fa-gavel',
        label: this.translateService.stream('dxp.sidebar.auction-sales'),
        link: `${AppRoutes.admin}/${AppRoutes.auctionSales}`,
        permission: ['dxp.auction-order.show', 'dxp.auction-order.manage'],
        key_index: 'auction_sales_index',
      },
      finance: {
        icon: 'fas fa-money-bill-wave',
        label: this.translateService.stream('dxp.sidebar.finance'),
        link: `${AppRoutes.admin}/${AppRoutes.finance}`,
        key_index: 'finance_index',
        permission: ['dxp.finance.dashboard'],
      },
      manage: {
        icon: 'fas fa-cogs',
        label: this.translateService.stream('dxp.sidebar.manage'),
        link: `${AppRoutes.admin}/${AppRoutes.manage}`,
        permission: [
          'setting.manage',
          'setting.manage.tenant',
          'translation.manage.tenant',
          'dxp.region.manage',
          'base-data-value.manage.tenant',
          'builders.config.manage',
          'builders.release.manage',
          'user.manage',
          'user.manage.tenant',
          'dxp.farm.user.show',
          'dxp.farm.user.manage',
          'role.manage.tenant',
          'dxp.farm-role.manage',
          'dxp.farm.manage',
          'dxp.farm.admin',
          'dxp.block.manage',
          'dxp.grading-station.manage',
          'dxp.package.manage',
          'dxp.farm-production-budget.manage',
          'dxp.village-planning.manage',
          'dxp.product.manage',
          'dxp.product-packing.manage',
          'dxp.floricode.import',
          'Floriday.trade-item.manage',
          'dxp.market-demand.manage',
          'dxp.delivery-condition.manage',
          'dxp.sales-channel.manage',
          'dxp.product-base-rate.manage',
          'dxp.product-base-rate.show',
          'dxp.price-rate.manage',
          'Floriday.delivery-order.manage',
          'Floriday.delivery-order.manage.tenant',
          'dxp.sales-contract.manage',
          'dxp.organisation.manage',
          'dxp.organisation.show',
          'dxp.harvest-delivery-note.manage',
          'dxp.observation.manage',
          'dxp.observation.show',
          'Floriday.batch.manage',
          'Floriday.batch.manage.tenant',
          'dxp.delivery-lag.manage',
          'dxp.location.manage',
          'dxp.airport-delivery-lag.manage',
          'transport.show',
          'transport.show.tenant',
          'dxp.rejected-harvest.manage',
          'dxp.graded-stock.manage',
          'dxp.boxed-stock.manage',
          'dxp.harvest-delivery-note.show',
          'dxp.rejected-harvest.show',
          'dxp.graded-stock.show',
          'dxp.boxed-stock.show',
          'user.show.tenant',
        ],
        key_index: 'manage_index',
      },
      profile: {
        icon: 'fas fa-fw fa-user',
        link: `${AppRoutes.admin}/${AppRoutes.user}/${AppRoutes.profile}`,
        label: this.translateService.stream('dxp.sidebar.my-profile'),
      },
    };
  }
}
